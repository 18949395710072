import englishMessages from 'ra-language-english';

export const messages = {
  resources: {
    general: "General",
    changeEstablishment:"Warning: you just changed the establishment ",
    'establishment-params': {
      name: 'Establishments param.',
      fields: {
        establishment: 'Establishment',
        enableEquipmentTesting: "Equipment testing",
        enableTimeInterval: "Time interval",
        enableSpecificExamTime: "Specific exam time",
        hasPicturesInChoices: "Pictures in choices",
        commentedTextGrading: "Commented text grading",
        hasQuestionBank: "Question bank",
        isSpreadsheetEnabled: "Spreadsheet question",
        enabledAIQuestionsGeneration: "Question generation via AI",
        enabledAiCorrection: "Intelligent Correction via AI.",
        facialIdentificationReferencePict: "Pre-validation of facial identification reference picture.",
      },
    },
    'establishments': {
      name: 'Establishments',
      fields: {
        name: 'Name',
        customSupportPhoneNumber: 'Support phone number',
        description: 'Description',
        email: 'Email',
        academicYear: 'Academic Year',
        learningGoalManaged: "Learning Goal",
        allowExportCopy: "Allow Export Copy",
        adminGradeSharing: "Admin",
        gradeSharing: "Grade Sharing",
        graderGradeSharing: "Grader",
        allowProctoring: "Allow Proctoring",
        timeZeroSecond: "Time Zero Second",
        proctoringStorageDuration: "Proctoring Storage Duration",
        subjects: 'Subjects',
        users: 'Users',
        classes: 'Classes',
        establishmentLanguage: "Establishment Language",
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        period: {
          period: 'Period',
          name: 'Period Name',
          description: 'Description',
          startDate: "Period Start Date",
          endDate: 'Period End Date',
          current: 'Is Current',
          createdAt: 'Created At',
          updatedAt: 'Updated At',
        },
        fromEmails: 'From Email',
        allowProctoringLive: "Allow Proctoring Live",
        allowProctoringAudioRecord: "Allow Proctoring Audio Record",
        allowEnvironment: "Allow Environment",
        lang: 'Language',
        info: 'Information',
        option: 'Option',
        hasSession: "Session Mode",
        durationFixed: "Duration is fixed",
        questionsPoints: "Show questions points",
        timeOptions: 'Option "Hide time options"',
        noCorrectAnswer: 'Option "No Correct Answer" response',
        allowEarlyExitFromRemoteExam: "Allow early exit from remote exam",
        multipleGrading: "Multiple grading",
        mandatoryTraining: "Disable training tests",
        virusAlert: "Virus alert",
        totalRamDetection: "Total RAM memory detection",
        disableMailing: "Mailing deactivation",
        disabledMailings: "Disabled email types",
        webApp: "Web mode",
        remoteExam: "External mode",
        subjectExportPermissions: "Subject export",
        desktopTargetVersion: "Desktop target version",
        desktopMinimalVersion: "Desktop minimal version"
      },
    },
    'academic-years': {
      name: 'Academic Year',
      fields: {
        name: 'Name',
        description: 'Description',
        startDate: "Start Date",
        endDate: 'End Date',
        establishment: 'Establishment',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
      }
    },
    'school-classes': {
      name: 'Classes',
      fields: {
        name: 'Name',
        academicYear: 'Academic Year',
        subjects: 'Subjects',
        students: 'Students',
        establishment: 'Establishment',
        deleted: 'Deleted',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        nbSubjects: 'Subjects Number',
        nbStudents: 'Students Number'
      }
    },
    'subjects': {
      name: 'Subject',
      fields: {
        name: 'Name',
        archived: 'Archived',
        description: 'Description',
        programs: 'programs',
        learningGoals: 'Learning Goals',
        learningObjectives: 'Learning Objectives',
        coefficients: 'Coefficients',
        establishment: 'Establishment',
        schoolClasses: 'Classes',
        subjectGroup: 'Subject Group',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
      }
    },
    'programs': {
      name: 'Programs',
      fields: {
        name: 'Name',
        description: 'Description',
        learningGoals: 'Learning Goals',
        subject: 'Subjects',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        establishment: 'Establishment',
        nbLearningGoals: 'Learning Goals Number',
        nbLearningObjectives: 'Learning Objectives Number'
      }
    },
    'users': {
      name: 'Users',
      fields: {
        firstName: 'First Name',
        lastName: 'Last Name',
        username: 'Username',
        email: 'Email',
        roles: 'Roles',
        hasExtraTime: 'Has Extra Time',
        blocked: 'Blocked',
        deleted: 'Deleted',
        schoolClasses: 'Classes',
        establishments: 'Establishments',
        exams: "Exams",
        createdBy: "Created By",
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        establishment: 'Establishment',
        user_roles: 'Roles',
        professorAssessmentTypes: 'Assessment Type (professor)',
        currentEstablishment: 'Current Establishment',
        user: 'User',
        hasTakenTraining:"Has Taken Training",
        takenTrainingDate:"Taken Training Date"
      },
    },
    'exams': {
      name: 'Exams',
      fields: {
        name: 'Name',
        subject: 'Subject',
        academicYear: 'Academic Year',
        professors: 'Professors',
        proctors: 'Proctors',
        classes: 'Classes',
        nbrClasses: 'Classes number',
        assessmentType: 'Assessment Type',
        startDate: 'Start Date and Hour (UTC) ',
        duration: 'Duration (SEC)',
        instructions: 'Instructions',
        status: 'Status',
        hash: 'Hash',
        publicKey: 'Public Key',
        proctorCode: 'Proctor Code',
        examParams: 'Exam Params',
        hasTimer: 'Has Timer',
        createdBy: 'Created By',
        establishment: 'Establishment',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        receivedStudentPapers: 'Received Student Paper',
        studentPapersDistributions: 'Student Papers Distributions',
        timezone: "Time Zone",
        maxPoints: "Max Points",
        timeZeroSecond: "Time Zero Second (SEC)"
      }
    },
    'student-paper-distributions': {
      name: 'Student Paper Distribution',
      fields: {
        grader: 'Grader',
        percentage: 'Percentage',
        assignedStudentPapers: 'Assigned Student Papers',
      }
    },
    'exam-params': {
      name: 'Exam Parameter',
      fields: {
        grader: 'Grader',
        percentage: 'Percentage',
        assignedStudentPapers: 'Assigned student papers',
        allowSpellChecker: "Allow spell Check",
        allowCopyAndPaste: 'Allow copy and paste ',
        anonymousCorrection: 'Anonymous correction',
        scientificCalculator: 'Scientific calculator',
        basicCalculator: 'Basic calculator',
        proctoring: 'Proctoring',
        openBook: 'Open book',
        allowTierTime: 'Allow tier time',
        deleteFileAfterFtpUpload: 'Delete file after upload (Ftp)',
        encryptFileOnFtp: 'Encrypt File On Ftp ',
        sendWithFtp: 'Send with Ftp',
        showNoCorrectAnswer: 'Show "No Correct Answer" response',
      }
    },
    'assessment-types': {
      name: 'Assessment Type',
      fields: {
        name: 'Name',
        doubleApproval: 'Double Approval',
        establishment: 'Establishment',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
      }
    },
    'lms-configurations': {
      name: 'LMS Configurations',
      fields: {
        name: 'Name',
        type: 'Type',
        token: 'Token',
        classes: 'Classes',
        subjects: 'Subjects',
        users: 'Users',
        enabled: 'Enabled',
        establishment: 'Establishment',
      }
    },
    'onboarding-custom-texts': {
      name: 'Onboarding custom texts',
      fields: {
        locale: 'Locale',
        statementTitle: 'Statement Title',
        statementSubtitle: 'Statement Subtitle',
        establishment: 'Establishment',
      }
    },
  },
  ...englishMessages,
};

export default messages;
